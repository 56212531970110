<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <h2 class="faledia-caption">Darstellungen vernetzen</h2>
      <v-card outlined>
        <v-card-title>Was sollen Schüler:innen lernen?</v-card-title>
        <v-card-text>
          Die Kinder können...
          <ul>
            <li>
              Darstellungsmittel den intendierten Zahlen und Aufgaben zuordnen
              (Lernstoff)
            </li>
            <li>
              Darstellungsmittel zur Darstellung einer Zahl nutzen (Lernhilfe)
            </li>
            <li>
              die Darstellung einer Zahl flexibel von einer Darstellung in eine
              andere übertragen
            </li>
            <li>
              die Erklärung einer anderen Person zu einem Darstellungswechsel
              wiedergeben
            </li>
            <li>ausgeführte Darstellungswechsel erklären</li>
          </ul>
        </v-card-text>
      </v-card>

      <br />

      <h3 class="faledia-caption">
        Was sollten Sie zum Vernetzen von Darstellungen wissen?
      </h3>

      <p>
        Als weiteres zentrales Merkmal des Stellenwertverständnisses gilt die
        Fähigkeit, zwischen den verschiedenen Darstellungen flexibel wechseln zu
        können (Fromme, 2017). Zu den Darstellungsweisen zählen:
      </p>

      <p>
        <strong>Mathesprache: </strong>Unter Mathesprache wird die
        Verschriftlichung einer Zahl verstanden – die sogenannten Zahlzeichen.
        Am häufigsten Verwendung findet die sogenannte Normalform (23). Neben
        dieser ‚Normalform‘ einer Zahl lassen sich weitere Schreibweisen
        unterscheiden, wie z. B. ‚2Z 3E‘ (Schreibweise mit Zehnern und Einern)
        oder 20 + 3 (Additive, stellengerechte Zerlegung der Zahl).
      </p>

      <p>
        <strong>Sprache: </strong> Im Gegensatz zur Notation von Zahlen, die
        regelgeleitet unter Benutzung des Stellenwertsystems und der arabischen
        Ziffern erfolgt, ist die Zahlwortbildung im deutschen Sprachraum von
        Unregelmäßigkeiten betroffen und unterscheidet sich z. T. erheblich von
        der Zahlwortbildung in anderen Sprachräumen. Bezogen auf zweistellige
        Zahlwörter ist das deutsche Zahlwortsystem beispielsweise durch
        Inkonsistenzen zwischen dem gesprochenen Zahlwort und der Abfolge der
        Ziffern in der geschriebenen Zahl (23 - twenty-three – drei-und-zwanzig)
        gekennzeichnet.
      </p>

      <p>
        <strong>Material: </strong> Bei der Darstellung größerer Zahlen greift
        man in der Regel nicht mehr auf Alltagsmaterialien, sondern auf
        didaktische Materialien zurück, welche die Strukturen unseres
        Dezimalsystems berücksichtigen. Dazu zählen zum Beispiel das
        Hunderterfeld, der Rechenrahmen oder auch Zehnersystemblöcke (Würfel,
        Stangen, Platten).
      </p>

      <p>
        <strong>Bilder: </strong>Auch hier gilt, dass zur Veranschaulichung
        sinnvollerweise bildliche Darstellungen genommen werden, die die Fünfer-
        bzw. die Zehnerstruktur und später die Hunderter- und die
        Tausenderstruktur zum Ausdruck bringen, wie zum Beispiel die Quadrat-,
        Strich-, Punktdarstellungen.
      </p>

      <div class="flex">
        <p>
          Mit Darstellungswechseln sind nun nicht nur die Übersetzungen zwischen
          den verschiedenen Darstellungsformen gemeint – z. B. von einem
          Zahlzeichen in das entsprechende Zahlwort. Übersetzungen können
          darüber hinaus auch innerhalb einer Zahlrepräsentation stattfinden, d.
          h. beispielsweise von einer Zahldarstellung in eine andere, z. B. 32 =
          30 + 2 = 3Z + 2E oder von einer bildlichen Darstellung in eine andere.
        </p>
        <div class="image">
          <AppExpandableImage
            contain
            :src="require('@/assets/swve/swve_darstellungswechsel.png')"
            parentId="swve"
            elementId="swve_darstellungswechsel.png"
          >
          </AppExpandableImage>
        </div>
      </div>

      <p>
        Die Lernenden müssen flexibel und verständig zwischen verschiedenen
        Zahldarstellungen hin- und herübersetzen können. Auch das Lesen,
        Schreiben und Sprechen von Zahlwörtern kann Lernhürden bereithalten (z.
        B. heißt es elf statt zehn-eins oder eins-zehn), die es gilt zu
        thematisieren.
      </p>

      <p>
        Ein tragfähiges Stellenwertverständnis zeichnet sich insbesondere
        dadurch aus, dass ein Kind weiß und erklären kann, wie die einzelnen
        Ziffern des Zahlzeichens (z.B. 426), die Bestandteile des Zahlwortes
        (z.B. vierhundert-sechs-und-zwanzig) und die Anzahlen der einzelnen
        Bündelungseinheiten (4 H, 2 Z, 6 E) zusammenhängen. Dazu kann die
        Durchführung von Darstellungswechseln beitragen. Von ganz zentraler
        Bedeutung ist stets auch der Austausch über den Darstellungswechsel:
        Warum passt das? Was ist gleich, was ist verschieden? etc. (Kuhnke,
        2012), weil dies zum Ausbau von Verständnis durch Herausarbeiten von
        Merkmalen beiträgt.
      </p>

      <p>
        In der nachfolgenden Tabelle sehen Sie verschiedene
        Darstellungsmöglichkeiten für unterschiedlichen Zahlen im 10er-System.
      </p>

      <v-simple-table class="imageTable">
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="text-center">
                <strong>223</strong>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/223_3.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/223_3.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/223_4.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/223_4.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/223_2.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/223_2.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/223_1.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/223_1.jpg"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <strong>32</strong>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/32_2.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/32_2.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/32_3.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/32_3.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/32_4.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/32_4.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/32_1.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/32_1.jpg"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <strong>203</strong>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/203_1.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/203_1.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/203_2.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/203_2.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/203_3.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/203_3.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/203_4.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/203_4.jpg"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <strong>2030</strong>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/2030_1.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/2030_1.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/2030_2.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/2030_2.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/2030_4.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/2030_4.jpg"
                  />
                </div>
              </td>
              <td>
                <div class="imageTableImages tableImageContainer">
                  <AppExpandableImage
                    :src="require('@/assets/swve/SDWH2_10er/2030_3.jpg')"
                    contain
                    parentId="SWVI"
                    elementId="SDWH2_10er/2030_3.jpg"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p>
        Im 10er-System können Sie sicherlich recht schnell nachvollziehen,
        weswegen die verschiedenen Darstellungen zu einer Zahl passen. Um aber
        auch zu erfahren, welche Herausforderung dies Kindern, die noch nicht
        sicher im 10er-System sind, bereiten kann, können Sie im Folgenden
        zusammenpassende Zahldarstellungen im 5er-System gruppieren.
      </p>

      <div style="background-color: white">
        <LernbausteinGruppen
          :id="LBSTGruppen[0].id"
          :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
          :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
          :showGroupNames="LBSTGruppen[0].showGroupNames"
          :workOrder="LBSTGruppen[0].workOrder"
          :possibleCategories="LBSTGruppen[0].possibleCategories"
          :elements="LBSTGruppen[0].elements"
          :responses="LBSTGruppen[0].responses"
        >
          <template v-slot:alternative-content>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <strong>(302)<sub>5</sub></strong>
                    </td>
                    <td>
                      <vuetify-audio
                        ref="audioPlayer1"
                        :file="
                          require('@/assets/swvi/SDWH2_5er/SWV-I_DW_1_Stellenwerttafel.mp3')
                        "
                        color="primary"
                        flat
                        :ended="
                          () =>
                            audioEnded(
                              'SDWH2_5er/SWV-I_DW_1_Stellenwerttafel.mp3'
                            )
                        "
                        @click.native="
                          () =>
                            audioPlayerClicked(
                              $event,
                              'SDWH2_5er/SWV-I_DW_1_Stellenwerttafel.mp3',
                              $refs.audioPlayer1
                            )
                        "
                        class="audioPlayer"
                      ></vuetify-audio>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/302_3.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/302_3.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/302_3.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <vuetify-audio
                        ref="audioPlayer2"
                        :file="
                          require('@/assets/swvi/SDWH2_5er/SWV-I_DW_2_Punktebild.mp3')
                        "
                        color="primary"
                        flat
                        :ended="
                          () =>
                            audioEnded('SDWH2_5er/SWV-I_DW_2_Punktebild.mp3')
                        "
                        @click.native="
                          () =>
                            audioPlayerClicked(
                              $event,
                              'SDWH2_5er/SWV-I_DW_2_Punktebild.mp3',
                              $refs.audioPlayer2
                            )
                        "
                        class="audioPlayer"
                      ></vuetify-audio>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/302_1.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/302_1.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/302_1.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <vuetify-audio
                        ref="audioPlayer3"
                        :file="
                          require('@/assets/swvi/SDWH2_5er/SWV-I_DW_3_Oehlsche_Schreibweise.mp3')
                        "
                        color="primary"
                        flat
                        :ended="
                          () =>
                            audioEnded(
                              'SDWH2_5er/SWV-I_DW_3_Oehlsche_Schreibweise.mp3'
                            )
                        "
                        @click.native="
                          () =>
                            audioPlayerClicked(
                              $event,
                              'SDWH2_5er/SWV-I_DW_3_Oehlsche_Schreibweise.mp3',
                              $refs.audioPlayer3
                            )
                        "
                        class="audioPlayer"
                      ></vuetify-audio>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/302_2.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/302_2.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/302_2.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <vuetify-audio
                        ref="audioPlayer4"
                        :file="
                          require('@/assets/swvi/SDWH2_5er/SWV-I_DW_4_Dienes-Material.mp3')
                        "
                        color="primary"
                        flat
                        :ended="
                          () =>
                            audioEnded(
                              'SDWH2_5er/SWV-I_DW_4_Dienes-Material.mp3'
                            )
                        "
                        @click.native="
                          () =>
                            audioPlayerClicked(
                              $event,
                              'SDWH2_5er/SWV-I_DW_4_Dienes-Material.mp3',
                              $refs.audioPlayer4
                            )
                        "
                        class="audioPlayer"
                      ></vuetify-audio>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/302_4.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/302_4.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/302_4.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <strong>(320)<sub>5</sub></strong>
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/320_3.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/320_3.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/320_3.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/320_1.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/320_1.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/320_1.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/320_2.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/320_2.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/320_2.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/320_4.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/320_4.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/320_4.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <strong>(322)<sub>5</sub></strong>
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/322_1.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/322_1.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/322_1.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/322_2.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/322_2.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/322_2.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/322_3.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/322_3.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/322_3.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/322_4.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/322_4.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/322_4.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <strong>(1233)<sub>5</sub></strong>
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/1233_2.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/1233_2.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/1233_2.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/1233_4.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/1233_4.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/1233_4.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/1233_3.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/1233_3.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/1233_3.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                    <td>
                      <div class="imageTableImages tableImageContainer">
                        <AppExpandableImage
                          :src="require('@/assets/swve/SDWH2_5er/1233_1.jpg')"
                          contain
                          parentId="SWVI"
                          elementId="SDWH2_5er/1233_1.jpg"
                        />
                      </div>
                      <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/1233_1.jpg')"
              class="imageTableImages"
            ></v-img> -->
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </LernbausteinGruppen>
      </div>

      <p>
        Darstellungen von Zahlen können sehr vielfältig sein und enthalten
        diverse Informationen. Für die Kinder stellen alle diese Darstellungen
        von Zahlen zunächst Lernstoff dar. Sie müssen lernen die Darstellungen
        auf allen vier Darstellungsebenen zu deuten, sie zueinander in Beziehung
        zu setzen und dabei die bedeutungsrelevanten Merkmale zu identifizieren.
      </p>

      <br />

      <AppLiteraturSWV />
    </v-card>

    <AppBottomNavSWV
      back="/stellenwertverstaendnis/hintergrundwissen/stellenwert-und-zahlenwert"
      next="/stellenwertverstaendnis/uebergang"
    />
  </div>
</template>

<script>
import AppLiteraturSWV from "@/common/AppLiteraturSWV";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppBottomNavSWV from "@/common/AppBottomNavSWV";
import AppHelper from "@/common/AppHelper";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_SDWH2_5er from "@/components/faledia/seiten/swve/beispiele/SDWH2_5er";

export default {
  components: {
    AppLiteraturSWV,
    AppBottomNavSWV,
    AppExpandableImage,
    LernbausteinGruppen,
    VuetifyAudio: () => import("vuetify-audio"),
  },
  data: () => ({
    LBSTGruppen: [LernbausteinGruppen_SDWH2_5er],
  }),
  methods: {
    audioEnded(id) {
      AppHelper.audioEnded(this, id);
    },

    audioPlayerClicked(event, id, ref) {
      AppHelper.audioPlayerClicked(this, event, id, ref);
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
  margin-block-end: 1%;
}
.image {
  max-width: 30%;
}
.imageTableImages {
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageTable {
  margin-bottom: 20px;
}

.tableImageContainer {
  width: 179px;
}

.biggerTableImageContainer {
  width: 390px;
}

.audioPlayer {
  width: 179px;
}

.audioPlayerBigger {
  width: 200px;
  margin: auto;
}
</style>
